import React, {useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Footer from "../components/theme/footer-landing";
import {parseContentfulShopifyProduct, postUrl, productUrl} from "../helpers";
import BaseModal from "../components/modals/base-modal";
import AddThis from "../components/add-this/add-this";

const ContentModalFamilyWalking = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>For the majority of animal lovers, pets are more than a source of companionship and love--they’re bonafide members of the family.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Family-And-Dog.png" alt="family playing with dog" data-lazy-class="img-solid" loading="lazy"/>
        <p>It’s no surprise then that when Americans embark on domestic or international travel, they want to bring their four-legged friends along for the journey. </p>
        <p>Unfortunately, for those canine companions and feline friends, travel via airplane isn’t always the most enjoyable experience.</p>
        <p>In fact, <strong>over 250 animals have died during or immediately after airline transport between 2010 and 2020,</strong> and the reasons why will shock you. </p>
      </div>
    </div>
  )
}

const ContentModalGrislyDeathToll = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>The United States Department of Transportation (DOT) requires that each airline in the U.S. submit a monthly report on the death,
          loss, or injury of an animal during air transportation. This information is made publicly available through the DOT's Consumer
          Report Archive, which extends as far back as two decades.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Chart-modal.png" alt="United States Department of Transportation pet report" data-lazy-class="img-solid" loading="lazy"/>
        <p>And the numbers are staggering. More than that, they’re heartbreaking.</p>
        <p>Between 2010 and 2020, over 250 animal passengers have died either during or immediately after airline travel. Over 170 have sustained injuries as a
          result of airline transport, while 20 animals were altogether lost by airlines.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/data-new.png" alt="pet airline deaths" data-lazy-class="img-solid" loading="lazy"/>
        <p>It’s important to note that these numbers only apply to animals considered as pets (“privately-owned companion animals” is the terminology used by the U.S.
          Department of Agriculture) and do not encompass animals shipped for research or resale, or animals on their way to sanctuaries. As a result, all numbers reported may be significantly higher.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/paws-modal.png" alt="U.S. Department of Agriculture terminology" data-lazy-class="img-solid" loading="lazy"/>
        <p className="text-center">Not all Animals are treated the same</p>
      </div>
    </div>
  )
}

const ContentModalShockingStatistics = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>More animals have died on Delta Airlines than on any other airline since 2010, accounting for over 30% of
          all recorded airline pet deaths in the U.S.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/shocking-statistics-modal-img.png" alt="delta airline pet deaths" data-lazy-class="img-solid" loading="lazy"/>
        <p>More animals have sustained injuries on United Airlines than on any other airline between 2010 and 2020,
          accounting for over 32% of all recorded airline pet injuries in the U.S. </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/shocking-statistics-modal-img2.png" alt="united airline pet deaths" data-lazy-class="img-solid" loading="lazy"/>
        <p>The airline with the highest incident rate in 2019 was Hawaiian Airlines, with a rate of 2.14%.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/shocking-statistics-modal-img3.png" alt="hawaiian airline pet deaths" data-lazy-class="img-solid" loading="lazy"/>
        <p>The airlines with the second and third highest incident rate in 2019 were United Airlines (1.21%) and American Airlines (1.12%). </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/shocking-statistics-modal-img4.png" alt="american airlines pet injuries" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalManBackpack = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Frequent fliers typically don’t think twice about stepping foot on an airplane. It’s a convenient means of transportation and one that delivers them to their intended destination in record time. Beyond that, it’s safe. </p>
        <p>But that safety sadly doesn’t always extend to four-legged passengers of the animal kind.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Dog.png" alt="dog injured on airplane" data-lazy-class="img-solid" loading="lazy"/>
        <p>Animals simply don’t understand the purpose of airline transport, much less why they’ve suddenly been subjected to a dark and frightening space for hours.</p>
        <p>This may explain why many animal incident reports filed by airlines often cite heart failure and anxiety-related episodes as causes of death once a veterinarian has performed a proper necropsy.  </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Chart.png" alt="dog found dead on airplane" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalBubbleInAugust = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>In August 2019, for instance, a Blue Heeler traveling on American Airlines was unresponsive upon arrival at Henry E. Rohlsen Airport, a veterinarian later concluding that the dog’s passing was related to travel-induced stress.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Heart-Beat.png" alt="Heart Beat" data-lazy-class="img-solid" loading="lazy"/>
        <p>Months later, in November 2019, an Abyssinian cat also traveling on American Airlines was found to have died of cardiac arrest. And in February 2020, a Yorkshire Terrier onboard a Delta Airlines flight was found to be unresponsive upon arriving at Los Angeles International Airport. The animal was immediately taken to a local emergency veterinary hospital, only to be pronounced dead just 25 minutes later. The incident report related to the animal’s death notes the possibility that the dog’s passing was potentially related to low blood sugar, but given that some of the symptoms of hypoglycemia are very similar to the symptoms of a panic attack, it remains to be seen whether or not travel-related anxiety was the true cause of the Yorkshire Terrier's death (the necropsy results have yet to be published). </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Dog-Scared.png" alt="Dog Scared" data-lazy-class="img-solid" loading="lazy"/>
        <p>The sad reality is that the typical commercial airplane is rife with stress-inducing experiences that even the most stout-hearted of canines and felines can have trouble braving.</p>
        <p>Unfortunately, with no voice of their own to raise concern, these animals continue to be subjected to unpleasant conditions when engaging in airline travel, many of which pet owners are completely unaware of.</p>
      </div>
    </div>
  )
}

const ContentModalBubbleSeparation = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Stress is a very real experience animals deal with onboard flights, and too often, it can be life-threatening. </p>
        <p>“Cortisol, known as the stress hormone, is not good for anything,” says Dr. Brian Bourquin, Co-Founder and Chief Medical Officer of <a href="https://www.bostonveterinary.com/" target="_blank">Boston Veterinary Clinic.</a> “Just like with humans, excess cortisol can interfere with insulin levels, it can weaken the immune system, and it keeps the animal in a fight or flight state rather than a relaxed state, which is needed for sleep, good digestion, etc. Just as flying can cause humans to be stressed or nervous, the same emotions can happen for our pets.”</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-60.png" alt="dog anxiety during travel" data-lazy-class="img-solid" loading="lazy"/>
        <p>Again, an animal isn’t able to understand why they’ve suddenly been separated from their human, why they’re surrounded by unfamiliar faces and smells, why they’re confined to a carrier, or why they’re being loaded into a dark space rife with frightening sounds. Worse, they don’t know when the entire experience will come to an end or when they’ll be reunited with their loved ones.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-61.png" alt="dog anxiety on airplane" data-lazy-class="img-solid" loading="lazy"/>
        <p>This ordeal alone is enough to cause any animal distress. And existing in a persistent state of anxiety, whereby an animal is suffering from elevated levels of stress hormones and an increased heart rate for hours, is a recipe for disaster. It can cause disease flare-ups, not to mention long-term effects such as lifelong phobias and severe bouts of anxiety any time your animal travels.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-62.png" alt="dog nervous on airplane" data-lazy-class="img-solid" loading="lazy"/>
        <p>Sadly, it can result in loss of life as well, such as in the case of the previously mentioned Blue Heeler traveling onboard American Airlines, who, in August 2019, died because of travel-induced stress.</p>
      </div>
    </div>
  )
}

const ContentModalBubblePhysical = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Over 38% of animal incidents reported by airlines between 2010 and 2020 have been related to animal injuries.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Chart-animal-incidents.png" alt="Physical Injury" data-lazy-class="img-solid" loading="lazy"/>
        <p>In June 2019, ramp personnel for an American Airlines flight refrained from loading a dog onto the Forward Cargo hold when they noticed the animal had two teeth pulled out as well as blood on his left paw from scrubbing at the back of his carrier. The dog was extremely stressed to the point of shaking and only calmed down when reunited with his owner, who immediately deboarded the plane to take the animal to a hospital. In July of the same year, a Labrador onboard a United Airlines flight in New Jersey chewed through her crate, resulting in a lost incisor and broken nail.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Dog-Injury.png" alt="Physical Injury" data-lazy-class="img-solid" loading="lazy"/>
        <p>Other incident reports from 2019 make note of dogs and cats with abrasions to the mouth, broken nails, and other self-inflicted injuries as a result of chewing at their carrier, a well-known behavior for which a common cause is anxiety.</p>
        <p>There are instances, too, when carriers can become damaged to the point of opening during transport, which may result in dangers such as the animal bolting from the cargo hold once personnel have opened it and eventually becoming lost (lost animals account for over 4% of reported incidents submitted by airlines between 2010 and 2020). Animals can also simply further injure themselves when trying to escape their carrier, and with no one to come to their aid for hours, such an ordeal can be traumatizing and may lead to hyperventilation as their stress levels increase.</p>
      </div>
    </div>
  )
}

const ContentModalBubbleRespiratory = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Anxiety is our body’s natural fear response. Animals can feel anxiety as well. And persistent anxiety, which many animals experience for the entire duration of a flight, can have a negative impact on respiration. Common symptoms of anxiety, for instance, include shortness of breath and hyperventilation.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Lungs.png" alt="Respiratory Issues" data-lazy-class="img-solid" loading="lazy"/>
        <p>Additionally, when an animal is stressed, their heart rate may rise, which can result in an increased need for oxygen. In a dog, heavy panting is a common sign of stress, as the animal tries to relax themselves. But not all dogs are built the same way, and for brachycephalic breeds, hyperventilation can often be a deadly episode.</p>
        <p>“Brachycephalic breeds (widely known as smoosh faces) inherently are born with a complex of issues that make breathing more difficult for them than other dogs that have normal noses,” says Dr. Brian Bourquin. “Dogs like Bulldogs, Pugs, and Boston Terriers all have stenotic nares, which means their nostrils are pinched or more narrow. They also often have extra tissue in the back of their throat, as well as an elongated soft palate, all of which makes breathing difficult for them on a good day, nevermind the dramatic changes in temperature, humidity, and pressure which are all likely to occur during air travel.”</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Dog-modal-3.png" alt="Respiratory Issues" data-lazy-class="img-solid" loading="lazy"/>
        <p>In fact, in May 2019, a Bulldog flying on Hawaiian Airlines was found unresponsive upon arriving at their destination airport. The cause of death on the airline’s incident report was noted as “airway insufficiency to a brachycephalic nose.”</p>
      </div>
    </div>
  )
}

const ContentModalBubbleWeatherConditions = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>No one looks forward to a flight in frightening weather conditions such as lightning storms or strong winds. The same holds true for animals. But weather conditions of varying kinds can make an already stressful experience even more unbearable for a dog or cat.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Frame.png" alt="Weather Conditions" data-lazy-class="img-solid" loading="lazy"/>
        <p>“One of the most common dangers for pets traveling via airline cargo hold is extreme temperatures,” says Dr. Heather Venkat, Companion Animal Veterinarian with <a href="https://vippuppies.com/" target="_blank">VIP Puppies.</a> “All dogs are at risk for being too cold and uncomfortable when transported via airline cargo, causing their immune system to take a hit and potentially making them more prone to getting sick.”</p>
        <p>The same holds true for extreme heat as well. The USDA provides strict <a href="https://www.aphis.usda.gov/aphis/pet-travel/pets-on-planes/lesson-2-pets-on-planes/care-and-handling-of-pets-during-air-travel" target="_blank">animal transport guidelines</a> to airlines, which state that “Dogs must not be exposed to air temperatures above 85°F or below 45°F for a period of more than 4 hours.” For dogs being moved to or from an airplane, the exposure time to these temperatures is reduced to 45 minutes.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Heat-Chart.png" alt="Weather Conditions" data-lazy-class="img-solid" loading="lazy"/>
        <p>However, considering that the <a href="https://www.insider.com/how-hot-does-a-car-get-in-the-sun" target="_blank">internal temperature of a car</a> can easily skyrocket to 104°F in just ten minutes when it’s only 85°F outside (the maximum exposure temperature allowed by the USDA), it’s not hard to see why a stressed dog confined in a carrier can easily overheat.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-47-modal4.png" alt="Weather Conditions" data-lazy-class="img-solid" loading="lazy"/>
        <p>And for those convinced that the ventilation slats in an animal carrier can make all the difference, you may need to think again. A <a href="https://pediatrics.aappublications.org/content/pediatrics/116/1/e109.full.pdf" target="_blank">study</a> published in 2005 found that cracking open a car’s windows on a hot day did little to avoid deadly internal temperatures. While closed windows saw temperatures rise 3.4°F every 5 minutes, windows opened by 1.5 inches still saw an increase of 3.1°F per 5-minute interval.</p>
        <p>A dog’s normal body temperature ranges from 101°F to 102.5°F, but if it exceeds 105°F, the animal should receive immediate medical attention. Anything north of 108°F puts them at a high risk of organ damage, kidney failure, and even brain swelling. </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Frame-modal4.png" alt="Weather Conditions" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalBubbleHearingLoss = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Any pet owner knows that the Fourth of July along with New Year’s Eve are the worst days to be a dog or a cat.</p>
        <p>When it comes to sound frequencies, the top end of a dog’s hearing range in particular can be as much as twice that of a human’s (45 kHz compared to a human’s measly 20 kHz).</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-43-modal-5.png" alt="Hearing Loss" data-lazy-class="img-solid" loading="lazy"/>
        <p>Needless to say, sounds that, to the human ear, are incredibly loud can have high-frequency tones that downright terrify a dog, hence why so many dogs exhibit signs of extreme stress and anxiety during firework shows or lightning storms. </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-44-modal-5.png" alt="Hearing Loss" data-lazy-class="img-solid" loading="lazy"/>
        <p>The sound pressure of fireworks is only ten decibels higher than the sound pressure of jet engines (140 dB vs. 130 dB), and both are louder than a live rock concert (which typically clocks in at 110 dB). </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-47-modal-5.png" alt="Hearing Loss" data-lazy-class="img-solid" loading="lazy"/>
        <p>Many people can experience temporary hearing loss after a loud concert, but according to <a href="https://www.healthyhearing.com/help/hearing-loss/noise-induced-hearing-loss" target="_blank">Healthy Hearing,</a> a jet engine can actually cause immediate and permanent hearing loss after just one close-range exposure, and unfortunately, the Forward Cargo holds where animals are kept during transport are often just a stone’s throw away from those havoc-wreaking engines. </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-modal-5.png" alt="Hearing Loss" data-lazy-class="img-solid" loading="lazy"/>
        <p>“There aren’t as many sound barriers in cargo,” says Dr. Brian Bourquin. “Given dogs have a greater ability to hear different levels of noise, the sound of jet engines can affect them even worse. Hearing loss, ringing of the ears, pressure, and other things dogs cannot communicate to us are likely to happen.”</p>
      </div>
    </div>
  )
}

const ContentModalBubbleDehydration = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>“Dehydration is another danger facing pets traveling via airline cargo hold,” says Dr. Heather Venkat. “Many pets refuse to drink their water or end up spilling all of their water in their crate, leading to dehydration, which can be risky during especially long flights.”</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-50-modal-6.png" alt="Dehydration" data-lazy-class="img-solid" loading="lazy"/>
        <p>Traveling by plane is a dehydrating experience for the human and animal body. It’s a result of lack of moisture in the cabin and low humidity levels. In fact, the humidity on a plane can be as much as 55% lower than the <a href="https://www.everydayhealth.com/healthy-travel/air-travel-and-dehydration.aspx" target="_blank">humidity</a> in a typical indoor room. </p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-45-modal-6.png" alt="Dehydration" data-lazy-class="img-solid" loading="lazy"/>
        <p>To combat the resulting dehydration, experts recommend drinking plenty of water. However, animals naturally don’t understand the importance of slaking their thirst while in transit. Many dogs and cats are simply too anxious to drink their water and may be more concerned with managing their heavy breathing. In other instances, rough handling of their carrier might’ve resulted in their entire supply of water spilling on the floor of their crate, or excessive and anxious movement on the animal’s part might’ve caused a similar spill.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Frame-modal-6.png" alt="Dehydration" data-lazy-class="img-solid" loading="lazy"/>
        <p>Whatever the case, a lack of water can subject the animal to a dehydrated state for the entire duration of their flight and for the duration of their recovery time (the amount of time it takes for them to be reunited with their owner, which can, in some cases, take up to two hours). Dehydration in dogs can result in a rapid heart rate, weakness, shivering, unconsciousness, and even death. Many of the airline incident reports on the DOT’s website relative to ‘unresponsive dogs’ may have very well been in part caused by dehydration.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-modal-6.png" alt="Dehydration" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalBubbleTemperatureContols = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Animals flying via airline cargo are always loaded onto the plane last and placed in a special area of the aircraft called the Forward Cargo. It’s a pressurized and temperature-controlled cargo hold, the temperature of which can be adjusted directly from the plane’s cockpit. Via something called a NOTOC (Notification to Captain), a pilot is able to see if live animals are loaded onto the Forward Cargo and will set the temperature for the hold accordingly.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-modal-7.png" alt="Temperature Contols" data-lazy-class="img-solid" loading="lazy"/>
        <p>Unfortunately, there can be unexpected malfunctions or oversights when it comes to the temperature and pressure control of the Forward Cargo hold.</p>
        <p>“This is the job of the pilots on the plane at the time of the flight,” says Randy Aronson, DVM and Owner of <a href="https://pawstucson.com/" target="_blank">Paws Veterinary Center</a> and Veterinary Advisor for <a href="https://betterpet.com/" target="_blank">BetterPet.</a> “However, there are instances where the heat or air conditioning has not been turned on or pressurization has not occurred, and deaths have taken place.”</p>
        <p>In fact, there’s more than one cautionary tale about such slip-ups. In September 2018, a Jet Airways flight from Mumbai to Jaipur saw 30 passengers suffer ear and nose bleeds when a pilot forgot to pressurize the cabin. The same happened in August 2005 in Greece, when a lack of cabin pressure (again, due to pilot oversight) rendered all 121 individuals onboard (including the pilots) unconscious. The plane eventually crashed, killing everyone.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-49-modal-7.png" alt="Temperature Contols" data-lazy-class="img-solid" loading="lazy"/>
        <p>There’s also another danger to the Forward Cargo hold, though, according to Airline Pilot and Public Speaker, <a href="https://flywithcaptainjoe.com/" target="_blank">Captain Joe Diebolder.</a> In the case of rapid depressurization, while passengers in the cabin have access to oxygen supply via the familiar masks that release from overhead panels, an animal in Forward Cargo won’t have breathable air until the pilot initiates an emergency descent.</p>
        <p>This descent happens relatively quickly, but the feeling of not being able to breathe, no matter how short the duration, can cause overwhelming stress to any passenger, person and pet alike, and that stress can easily exacerbate other issues, especially in older animals or those with underlying health conditions.</p>
      </div>
    </div>
  )
}

const ContentModalAlternativesForPets = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>If you’re traveling by plane, most major airlines will allow you to bring a pet inside the cabin, provided it’s small enough to fit inside a kennel and that the kennel in question remains under the seat in front of you for the entirety of the flight. You will have to pay a pet fee each way of your trip, which typically ranges from $75-$125.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-63-modal.png" alt="Travel Alternatives for Pets" data-lazy-class="img-solid" loading="lazy"/>
        <p>There will also be other restrictions you must follow, so be sure to always research your airline’s pet policies thoroughly before the day of your departure.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Woman-Pet-Seat.png" alt="Travel Alternatives for Pets" data-lazy-class="img-solid" loading="lazy"/>
        <p>If your dog is too large to fit inside an airline carrier and you have to check them in for transport via cargo, heed this advice from Airline Pilot Captain Joe Diebolder: "Don't bring your pet on your holiday flight. It's a super stressful experience for your beloved animal. The noise, the heat, the cold, the darkness, and the on-and-off loading are unpleasant situations for your best buddy."</p>
        <p>Instead, consider the following alternatives to avoid the dangers and risks associated with cargo travel:</p>
      </div>
    </div>
  )
}

const ContentModalHireaPetSitter = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>While it can be disheartening to leave your faithful friend behind during your travels, for some animals (especially those with pre-existing health conditions, those who are older in age, or those who are brachycephalic breeds), it can ultimately be the best option. Ask friends or family members if they’re willing and able to care for your pet while you’re gone,</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Couch.png" alt="Hire a Pet Sitter" data-lazy-class="img-solid" loading="lazy"/>
        <p>or turn to websites like TrustedHousesitters, Care.com, or Rover to find a reliable sitter who can give your animal one-on-one, special TLC in your absence. Animal daycares such as <a href="https://www.dogtopia.com/" target="_blank">Dogtopia</a> are another option and are located in countless U.S. cities across the nation.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Group-72.png" alt="Hire a Pet Sitter" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalTaketheRoad = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>In the wake of COVID-19, the way we travel has changed significantly in the United States. Google searches for RV rentals since January 2020, for instance, have skyrocketed, in some cases increasing by 4,600%.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Chart-ttr-modal.png" alt="Take the Road" data-lazy-class="img-solid" loading="lazy"/>
        <p>Searches for <a href="https://www.honestpaws.com/blog/hiking-with-dogs/" target="_blank">national parks</a>, nearby campgrounds, and road trips have also inflated, hinting at the new method of traveling so many Americans are taking part in. Instead of flying to your domestic destination, why not pack up a rental and hit the road with your loving pet? They’ll no doubt enjoy the new sights and smells, and you can make use of websites like GoPetFriendly to uncover pet-friendly destinations and also plan pet-friendly rest stops along your journey.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Van-ttr-modal.png" alt="Take the Road" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalTravelbyRail = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Amtrak allows passengers to travel with pets, though there are certain weight restrictions in place. Another option, especially for those embarking on international travel, is to take to the seas with your furry friend. There are many options available when it comes to pet-friendly private yachts and ferries, not to mention the popular cruise ship for pets: Cunard’s Queen Mary 2. This luxurious ocean liner travels between New York and Southampton, England and provides only the best white-glove service to the ship’s four-legged passengers, including daily walks, freshly-baked biscuits, and playtime and exercise in a shielded, open-deck area.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Cruise-Ship.png" alt="Travel by Rail" data-lazy-class="img-solid" loading="lazy"/>
      </div>
    </div>
  )
}

const ContentModalFlyPrivately = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-12 pet-airlines-modal-container mx-auto text-center">
        <p>Chartering a private jet can cost more than the average annual income for most Americans. However, there are clever ways to enjoy private jet privileges (flying with your pet beside you, for instance) that won’t require you to pay astronomical fees. Seat sharing is one option. Private jets sometimes have an empty seat that needs to be filled and will offer it at a steep discount. You can call private aviation companies at any time and ask if there are any last-minute deals available. You can also search for seats through companies like Fly XO, Jet Linx, and Wheels Up. Typically, rates will be comparable to what you would normally pay for a first-class ticket on a major airline but can sometimes be less than that as well.</p>
        <img className='py-4' src="/img/landing-pages/pet-airlines/Airplane.png" alt="Fly Privately" data-lazy-class="img-solid" loading="lazy"/>
        <p>For many Americans, travel continues to be a staple of a life well-lived, and for those who enjoy the companionship and love of an animal friend, travel’s just not the same unless Spot or Fluffy are by their side.</p>
        <p>However, as long as transport via airline cargo continues to pose risks to an animal’s health and well-being, pet parents should opt for travel alternatives that keep their furry friend safe and content.</p>
        <p>Not only will this give you better peace of mind, but it’ll ensure that your four-legged family member stays happy, healthy, and very much well loved.</p>
      </div>
    </div>
  )
}

const ContentModalShareOptions = () => {
  return (
    <div className='content-modal row pet-airlines-modal'>
      <div className="col-8 py-5 pet-airlines-modal-container mx-auto text-center">
        <AddThis>
          <div className="addthis_toolbox addthis_default_style sharing-options-networks">
            <a className="addthis_button_facebook mx-1" title="Share on Facebook"><img src="/img/the-great-hemp-scam/blog-main/social-facebook.svg" className="sharing-option" alt="facebook"/></a>
            <a className="addthis_button_twitter mx-1" title="Share on Twitter"><img src="/img/the-great-hemp-scam/blog-main/social-twitter.svg" className="sharing-option" alt="twitter" /></a>
            <a className="addthis_button_pinterest mx-1" title="Share on Pinterest"><img src="/img/the-great-hemp-scam/blog-main/social-pinterest.svg" className="sharing-option" alt="pinterest" /></a>
            <a className="addthis_button_linkedin mx-1" title="Share on Linkedin"><img src="/img/the-great-hemp-scam/blog-main/social-linkedin.svg" className="sharing-option" alt="linkedin" /></a>
            <a className="addthis_button_blogger mx-1" title="Share on Blogger"><img src="/img/the-great-hemp-scam/blog-main/social-blogger.svg" className="sharing-option" alt="blogger" /></a>
          </div>
        </AddThis>
      </div>
    </div>
  )
}

class PetAirlinesPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'The 5 Worst Pet Friendly Airlines: Shocking Statistics | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.ogImage = this.props.data.ogImage.childImageSharp.fluid

    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Over 250 animals have died on airlines since 2010. Discover the shocking secrets airlines don\'t want to tell you about transporting pets, and who the 5 worst pet friendly airlines are.',
        image: this.ogImage
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url'  />
      ]
    }

    this.backgroundContainers = {
      desktop: {
        firstContainer: {
          width: 3512
        },
        secondContainer: {
          width: 7257
        },
        thirdContainer: {
          width: 10333
        },
        fourthContainer: {
          width: 13355
        },
        fifthContainer: {
          width: 16055
        },
        sixthContainer: {
          width: 19855
        }
      },
      mobile:{
        firstContainer: {
          width: 2033
        },
        secondContainer: {
          width: 4211
        },
        thirdContainer: {
          width: 6108
        },
        fourthContainer: {
          width: 7956
        },
        fifthContainer: {
          width: 9610
        },
        sixthContainer: {
          width: 11953
        }
      }
    }

    this.animationRef = null
    this.crateRef = null
    this.shareBtnMobileRef = null
    this.shareBtnRef = null

    this.carDesktopRef = null
    this.carMobileRef = null
    this.dogAnimationCarFixedRef = null

    this.containerRef = null
    this.secondContainerRef = null
    this.thirdContainerRef = null
    this.fourthContainerRef = null
    this.fifthContainerRef = null
    this.sixthContainerRef = null

    this.modalFamilyWalkingContent = null
    this.modalGrislyDeathToll = null
    this.modalShockingStatistics = null
    this.modalManBackpack = null
    this.modalBubbleInAugust = null
    this.modalBubbleSeparation = null
    this.modalBubblePhysical = null
    this.modalBubbleRespiratory = null
    this.modalBubbleWeatherConditions = null
    this.modalBubbleHearingLoss = null
    this.modalBubbleDehydration = null
    this.modalBubbleTemperatureContols = null
    this.modalAlternativesForPets = null
    this.modalHireaPetSitter = null
    this.modalTaketheRoad = null
    this.modalTravelbyRail = null

    this.modalShareOptions = null

    this.state = {
      vh: 0
    }

    this.displayContent = this.displayContent.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleScrollLeftToContainer = this.handleScrollLeftToContainer.bind(this)
    this.scrollHorizontally = this.scrollHorizontally.bind(this)
  }

  componentDidMount() {
    this.containerRef.addEventListener('scroll', this.handleScroll)

    if (this.containerRef.addEventListener) {
      // IE9, Chrome, Safari, Opera
      this.containerRef.addEventListener("mousewheel", this.scrollHorizontally, false);
      // Firefox
      this.containerRef.addEventListener("DOMMouseScroll", this.scrollHorizontally, false);
    } else {
      // IE 6/7/8
      this.containerRef.attachEvent("onmousewheel", this.scrollHorizontally);
    }

    setTimeout(() => {
      this.handleScrollLeftToContainer()
      this.setState({ vh: window.innerHeight })
    }, 500);
  }

  componentWillUnmount() {
    this.containerRef.removeEventListener('scroll', this.handleScroll)
  }

  /**
   * handle how much to scroll is needed in the load of the page, for different devices
   */
  handleScrollLeftToContainer() {
    var windowWidth = window.innerWidth
    var titleContainerWidth = 0
    var scrollLeftContainer = 0
    var marginLeft = 0

    if (windowWidth < 768) {
      marginLeft = 350
      titleContainerWidth = 345
    } else {
      marginLeft = 660
      titleContainerWidth = 370
    }

    scrollLeftContainer = marginLeft - ((windowWidth - titleContainerWidth) / 2)

    this.containerRef.scrollLeft += scrollLeftContainer
  }

  /**
   * handle horizontal scroll in order to show backgrounds when necesary
   * @param e
   */
  handleScroll(e) {
    const currentPageXOffset = this.containerRef.scrollLeft
    const windowWidth = window.innerWidth
    var backgroundContainer = {}
    var carContainerXOffset = 0
    if (windowWidth < 768) {
      backgroundContainer = this.backgroundContainers.mobile
      carContainerXOffset = 9940
    } else {
      backgroundContainer = this.backgroundContainers.desktop
      carContainerXOffset = 16200
    }

    if (currentPageXOffset > carContainerXOffset) {
      this.crateRef.className = 'dog-pet-airline fadeout'
      this.animationRef.className = 'dog-crate fadeout'
      this.shareBtnMobileRef.className = 'crate-share mobile fadeout'
      this.shareBtnRef.className = 'crate-share desktop fadeout'

      this.carDesktopRef.className = 'car-desktop car-desktop-fixed fadein'
      this.carMobileRef.className = 'car-mobile car-mobile-fixed fadein'
      this.dogAnimationCarFixedRef.className = 'dog-inside-car fadein'
    } else {
      this.crateRef.className = 'dog-pet-airline fadein'
      this.animationRef.className = 'dog-crate fadein'
      this.shareBtnMobileRef.className = 'crate-share mobile fadein'
      this.shareBtnRef.className = 'crate-share desktop fadein'

      this.carDesktopRef.className = 'car-desktop fadeout'
      this.carMobileRef.className = 'car-mobile fadeout'
      this.dogAnimationCarFixedRef.className = 'dog-inside-car fadeout'
    }

    if (currentPageXOffset > (backgroundContainer.fifthContainer.width - windowWidth - 300) ) {
      this.sixthContainerRef.className = 'bg-container sixth-bg-container d-inline-block'
    } else if (currentPageXOffset > (backgroundContainer.fourthContainer.width - windowWidth - 300) ) {
      this.fifthContainerRef.className = 'bg-container fifth-bg-container d-inline-block'
    } else if (currentPageXOffset > (backgroundContainer.thirdContainer.width - windowWidth - 300) ) {
      this.fourthContainerRef.className = 'bg-container fourth-bg-container d-inline-block'
    } else if (currentPageXOffset > (backgroundContainer.secondContainer.width - windowWidth - 300) ) {
      this.thirdContainerRef.className = 'bg-container third-bg-container d-inline-block'
    } else if (currentPageXOffset > (backgroundContainer.firstContainer.width - windowWidth - 300) ) {
      this.secondContainerRef.className = 'bg-container second-bg-container d-inline-block'
    }
  }

  /**
   * horizontal scroll with mouse wheel
   * @param e
   */
  scrollHorizontally(e) {
    e = window.event || e;
    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
    var scrollSpeed = 30;
    this.containerRef.scrollLeft -= (delta * scrollSpeed);
    e.preventDefault();
  }

  showShareDialog(e) {
    e && e.preventDefault();
    navigator && navigator.share && navigator.share({
      title: 'Pet Airlines Deaths',
      text: 'Here Are The Shocking Secrets Airlines Don’t Want to Tell You About Transporting Pets',
      url: this.props.location.href,
    });
  }

  /**
   * Displays content inside a modal
   * @param  {event} e
   * @return {void}
   */
  displayContent(modal) {
    modal.showModal()
  }

  render() {

    const sectionsDesktopImages = {
      firstSection: [
        {
          alt: 'Finger Gesture',
          src: '/img/landing-pages/pet-airlines/finger-gesture.svg',
          left: 790,
          top: 445,
          class: 'finger-gesture',
        },{
          alt: 'family walking to pet friendly airlines',
          src: '/img/landing-pages/pet-airlines/Family-Walking.png',
          height: 250,
          left: 1300,
          top: 480,
        },{
          alt: 'Entrance Signs',
          src: '/img/landing-pages/pet-airlines/Entrance-Signs.png',
          left: 3375,
          top: 0,
          width: 290
        },{
          alt: 'Man Entrance',
          src: '/img/landing-pages/pet-airlines/Man-Entrance.png',
          left: 3410,
          top: 405,
          width: 230
        }
      ],
      secondSection: [
        {
          alt: 'Plant Left',
          src: '/img/landing-pages/pet-airlines/Plant-Left.png',
          left: 670,
          top: 425,
        },
        {
          alt: 'Sign',
          src: '/img/landing-pages/pet-airlines/Sign-new.png',
          left: 845,
          top: 0,
          width: 335,
          class: 'sign'
        },
        {
          alt: 'United Airlines',
          src: '/img/landing-pages/pet-airlines/United-Airlines.png',
          left: 865,
          top: 235,
        },
        {
          alt: 'Seats Empty',
          src: '/img/landing-pages/pet-airlines/Seats-Empty.png',
          left: 1080,
          top: 450,
        },
        {
          alt: 'Delta',
          src: '/img/landing-pages/pet-airlines/Delta.png',
          left: 1290,
          top: 232,
        },
        {
          alt: 'Plant Middle',
          src: '/img/landing-pages/pet-airlines/Plant-Middle.png',
          left: 1540,
          top: 375,
        },
        {
          alt: 'Alaska Airlines',
          src: '/img/landing-pages/pet-airlines/Alaska-Airlines.png',
          left: 1680,
          top: 200,
        },
        {
          alt: 'Man Seat',
          src: '/img/landing-pages/pet-airlines/Man-Seat.png',
          left: 1760,
          top: 380,
        },
        {
          alt: 'American Airlines',
          src: '/img/landing-pages/pet-airlines/American-Airlines.png',
          left: 2015,
          top: 230,
        },
        {
          alt: 'Daughter Mother Seat',
          src: '/img/landing-pages/pet-airlines/Daughter-Mother-Seat.png',
          left: 2270,
          top: 410,
          width: 450
        },
        {
          alt: 'Hawaiian Airlines Logo',
          src: '/img/landing-pages/pet-airlines/Hawaiian-Airlines-Logo.png',
          left: 2450,
          top: 215,
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          left: 2720,
          top: 375,
        },
        {
          alt: 'Info Container',
          src: '/img/landing-pages/pet-airlines/info-container.png',
          left: 2810,
          top: 120,
        },
        {
          alt: 'Info Data',
          src: '/img/landing-pages/pet-airlines/info-data.png',
          left: 2845,
          top: 385,
        },
        {
          alt: 'Man Looking',
          src: '/img/landing-pages/pet-airlines/Man-Looking.png',
          left: 3010,
          top: 365,
        },
        {
          alt: 'Man Suitcase',
          src: '/img/landing-pages/pet-airlines/Man-Suitcase.png',
          left: 3400,
          top: 170,
        },
      ],
      thirdSection: [
        {
          alt: 'Man Backpack',
          src: '/img/landing-pages/pet-airlines/Man-Backpack.png',
          left: 50,
          top: 120,
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          left: 540,
          top: 378,
        },
        {
          alt: 'Man Double Suit Case',
          src: '/img/landing-pages/pet-airlines/Man-Double-Suit-Cas.png',
          left: 700,
          top: 350,
        },
        {
          alt: 'Bubble In August',
          src: '/img/landing-pages/pet-airlines/Bubble-In-August.png',
          left: 900,
          top: 150,
        },
        {
          alt: 'Man Standing Window',
          src: '/img/landing-pages/pet-airlines/Man-Standing-Window.png',
          left: 1500,
          top: 370,
        },
        {
          alt: 'Empty Chairs',
          src: '/img/landing-pages/pet-airlines/Empty-Chairs.png',
          left: 1810,
          top: 470,
        },
        {
          alt: 'Bubble Fun Fact',
          src: '/img/landing-pages/pet-airlines/Bubble-Fun-Fact.png',
          left: 2160,
          top: 170,
          width: 350
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          left: 2480,
          top: 378,
        },
        {
          alt: 'Plant Left',
          src: '/img/landing-pages/pet-airlines/Plant-Left.png',
          left: 2815,
          top: 425,
        },
        {
          alt: 'TV',
          src: '/img/landing-pages/pet-airlines/TV.png',
          left: 2720,
          top: 0,
        },
      ],
      fourthSection: [
        {
          alt: 'Man Escalator',
          src: '/img/landing-pages/pet-airlines/Man-Escalator.png',
          left: 25,
          top: 367,
        },
        {
          alt: 'Old Couple',
          src: '/img/landing-pages/pet-airlines/Old-Couple.png',
          left: 470,
          top: 420,
        },
        {
          alt: 'Dark Truth Suit Cases',
          src: '/img/landing-pages/pet-airlines/Dark-Truth-Suit-Cases.png',
          left: 870,
          top: 190,
        },
        {
          alt: 'Bubble 1 Separation',
          src: '/img/landing-pages/pet-airlines/Bubble-1-Separation.png',
          left: 1470,
          top: 170,
        },
        {
          alt: 'Suitases',
          src: '/img/landing-pages/pet-airlines/Suitases.png',
          left: 2010,
          top: 550,
        },
        {
          alt: 'Bubble 2 Physical',
          src: '/img/landing-pages/pet-airlines/Bubble-2-Physical.png',
          left: 2090,
          top: 230,
        },
      ],
      fifthSection: [
        {
          alt: 'Bubble 3 Respiratory',
          src: '/img/landing-pages/pet-airlines/Bubble-3-Respiratory.png',
          left: 0,
          top: 180,
        },
        {
          alt: 'Bubble 4 Weather Controls',
          src: '/img/landing-pages/pet-airlines/Bubble-4-Weather-Controls.png',
          left: 720,
          top: 200,
        },
        {
          alt: 'Plane',
          src: '/img/landing-pages/pet-airlines/Plane.png',
          left: 1720,
          top: 90,
          width: 1600,
        },
        {
          alt: 'Bubble 5 Hearing Loss',
          src: '/img/landing-pages/pet-airlines/Bubble-5-Hearing-Loss.png',
          left: 1380,
          top: 170,
        },
        {
          alt: 'Bubble 6 Dehydration',
          src: '/img/landing-pages/pet-airlines/Bubble-6-Dehydration.png',
          left: 2440,
          top: 135,
          width: 350
        },
        {
          alt: 'Bubble 7 Temp Control',
          src: '/img/landing-pages/pet-airlines/Bubble-7-Temp-Control.png',
          left: 2840,
          top: 135,
          width: 350
        },
      ],
      sixthSection: [
        {
          alt: 'Travel Alternatives For Pets',
          src: '/img/landing-pages/pet-airlines/Travel-Alternatives-For-Pets.png',
          left: 880,
          top: 200,
        },
        {
          alt: 'Hire A Pet Sitter',
          src: '/img/landing-pages/pet-airlines/1-Hire-A-Pet-Sitter.png',
          left: 1260,
          top: 400,
          width: 334
        },
        {
          alt: 'Take The Road',
          src: '/img/landing-pages/pet-airlines/2-Take-The-Road.png',
          left: 1640,
          top: 150,
        },
        {
          alt: 'Travel By Rail',
          src: '/img/landing-pages/pet-airlines/3-Travel-By-Rail.png',
          left: 2285,
          top: 335,
        },
        {
          alt: 'Fly Privately',
          src: '/img/landing-pages/pet-airlines/4-Fly-Privately.png',
          left: 2730,
          top: 195,
          width: 224
        },
      ]
    }
    const sectionsMobileImages = {
      firstSection: [
        {
          alt: 'Finger Gesture',
          src: '/img/landing-pages/pet-airlines/finger-gesture.svg',
          left: 450,
          top: 330,
          class: 'finger-gesture',
        },{
          alt: 'family walking to pet friendly airlines',
          src: '/img/landing-pages/pet-airlines/Family-Walking.png',
          height: 200,
          left: 800,
          top: 280,
        },{
          alt: 'pet airlines boarding monitor',
          src: '/img/landing-pages/pet-airlines/Entrance-Signs.png',
          left: 1930,
          top: 0,
          width: 200
        },{
          alt: 'man traveling in pet airport',
          src: '/img/landing-pages/pet-airlines/Man-Entrance.png',
          left: 1950,
          top: 270,
          width: 150
        }
      ],
      secondSection: [
        {
          alt: 'Plant Left',
          src: '/img/landing-pages/pet-airlines/Plant-Left.png',
          left: 270,
          top: 260,
          width: 120
        },
        {
          alt: 'The worst airlines for pet travel',
          src: '/img/landing-pages/pet-airlines/Sign-new.png',
          left: 390,
          top: 0,
          width: 205,
        },
        {
          alt: 'united airlines pet policy',
          src: '/img/landing-pages/pet-airlines/United-Airlines.png',
          left: 400,
          top: 145,
          width: 180
        },
        {
          alt: 'Seats Empty',
          src: '/img/landing-pages/pet-airlines/Seats-Empty.png',
          left: 520,
          top: 290,
          width: 180
        },
        {
          alt: 'delta airlines pet policy',
          src: '/img/landing-pages/pet-airlines/Delta.png',
          left: 658,
          top: 140,
          width: 145
        },
        {
          alt: 'Plant Middle',
          src: '/img/landing-pages/pet-airlines/Plant-Middle.png',
          left: 810,
          top: 241,
          width: 65
        },
        {
          alt: 'alaska airlines pet policy',
          src: '/img/landing-pages/pet-airlines/Alaska-Airlines.png',
          left: 895,
          top: 118,
          width: 147
        },
        {
          alt: 'Man Seat',
          src: '/img/landing-pages/pet-airlines/Man-Seat.png',
          left: 950,
          top: 252,
          width: 220
        },
        {
          alt: 'american airlines pet policy',
          src: '/img/landing-pages/pet-airlines/American-Airlines.png',
          left: 1102,
          top: 138,
          width: 215
        },
        {
          alt: 'Daughter Mother Seat',
          src: '/img/landing-pages/pet-airlines/Daughter-Mother-Seat.png',
          left: 1270,
          top: 260,
          width: 280
        },
        {
          alt: 'hawaiian airlines pet policy',
          src: '/img/landing-pages/pet-airlines/Hawaiian-Airlines-Logo.png',
          left: 1370,
          top: 124,
          width: 155
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          left: 1540,
          top: 233,
          width: 70
        },
        {
          alt: 'Info Container',
          src: '/img/landing-pages/pet-airlines/info-container.png',
          left: 1600,
          top: 76,
          width: 180
        },
        {
          alt: 'Info Data',
          src: '/img/landing-pages/pet-airlines/info-data.png',
          left: 1615,
          top: 240,
          width: 150
        },
        {
          alt: 'Man Looking',
          src: '/img/landing-pages/pet-airlines/Man-Looking.png',
          left: 1713,
          top: 230,
          width: 180
        },
        {
          alt: 'Man Suitcase',
          src: '/img/landing-pages/pet-airlines/Man-Suitcase.png',
          left: 1950,
          top: 100,
          width: 195
        },
      ],
      thirdSection: [
        {
          alt: 'Man Backpack',
          src: '/img/landing-pages/pet-airlines/Man-Backpack.png',
          left: 10,
          top: 65,
          width: 260
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          top: 235,
          left: 330,
          width: 70
        },
        {
          alt: 'Man Double Suit Case',
          src: '/img/landing-pages/pet-airlines/Man-Double-Suit-Cas.png',
          left: 410,
          top: 210,
          width: 150
        },
        {
          alt: 'Bubble In August',
          src: '/img/landing-pages/pet-airlines/Bubble-In-August.png',
          left: 550,
          top: 65,
          width: 300
        },
        {
          alt: 'Man Standing Window',
          src: '/img/landing-pages/pet-airlines/Man-Standing-Window.png',
          left: 910,
          top: 225,
          width: 140
        },
        {
          alt: 'Empty Chairs',
          src: '/img/landing-pages/pet-airlines/Empty-Chairs.png',
          left: 1110,
          top: 290,
          width: 190
        },
        {
          alt: 'Bubble Fun Fact',
          src: '/img/landing-pages/pet-airlines/Bubble-Fun-Fact.png',
          left: 1330,
          top: 100,
          width: 220
        },
        {
          alt: 'Plant Right',
          src: '/img/landing-pages/pet-airlines/Plant-Right.png',
          left: 1525,
          top: 217,
          width: 80
        },
        {
          alt: 'Plant Left',
          src: '/img/landing-pages/pet-airlines/Plant-Left.png',
          left: 1700,
          top: 244,
          width: 140
        },
        {
          alt: 'TV',
          src: '/img/landing-pages/pet-airlines/TV.png',
          left: 1650,
          top: 0,
          width: 200
        },
      ],
      fourthSection: [
        {
          alt: 'Man Escalator',
          src: '/img/landing-pages/pet-airlines/Man-Escalator.png',
          left: 20,
          top: 208,
          width: 80
        },
        {
          alt: 'Old Couple',
          src: '/img/landing-pages/pet-airlines/Old-Couple.png',
          left: 260,
          top: 260,
          width: 200
        },
        {
          alt: 'Dark Truth Suit Cases',
          src: '/img/landing-pages/pet-airlines/Dark-Truth-Suit-Cases.png',
          left: 550,
          top: 130,
          width: 330
        },
        {
          alt: 'Bubble 1 Separation',
          src: '/img/landing-pages/pet-airlines/Bubble-1-Separation.png',
          left: 900,
          top: 100,
          width: 250
        },
        {
          alt: 'Suitases',
          src: '/img/landing-pages/pet-airlines/Suitases.png',
          left: 1200,
          top: 315,
          width: 120
        },
        {
          alt: 'Bubble 2 Physical',
          src: '/img/landing-pages/pet-airlines/Bubble-2-Physical.png',
          left: 1270,
          top: 120,
          width: 260
        },
      ],
      fifthSection: [
        {
          alt: 'Bubble 3 Respiratory',
          src: '/img/landing-pages/pet-airlines/Bubble-3-Respiratory.png',
          left: 0,
          top: 110,
          width: 360
        },
        {
          alt: 'Bubble 4 Weather Controls',
          src: '/img/landing-pages/pet-airlines/Bubble-4-Weather-Controls.png',
          left: 435,
          top: 95,
          width: 280
        },
        {
          alt: 'Plane',
          src: '/img/landing-pages/pet-airlines/Plane.png',
          left: 1000,
          top: 60,
          width: 1030
        },
        {
          alt: 'Bubble 5 Hearing Loss',
          src: '/img/landing-pages/pet-airlines/Bubble-5-Hearing-Loss.png',
          left: 750,
          top: 85,
          width: 270
        },
        {
          alt: 'Bubble 6 Dehydration',
          src: '/img/landing-pages/pet-airlines/Bubble-6-Dehydration.png',
          left: 1460,
          top: 80,
          width: 230
        },
        {
          alt: 'Bubble 7 Temp Control',
          src: '/img/landing-pages/pet-airlines/Bubble-7-Temp-Control.png',
          left: 1740,
          top: 80,
          width: 230
        },
      ],
      sixthSection: [
        {
          alt: 'Travel Alternatives For Pets',
          src: '/img/landing-pages/pet-airlines/Travel-Alternatives-For-Pets.png',
          left: 530,
          top: 100,
          width: 250
        },
        {
          alt: 'Hire A Pet Sitter',
          src: '/img/landing-pages/pet-airlines/1-Hire-A-Pet-Sitter.png',
          left: 760,
          top: 230,
          width: 270
        },
        {
          alt: 'Take The Road',
          src: '/img/landing-pages/pet-airlines/2-Take-The-Road.png',
          left: 1010,
          top: 80,
          width: 270
        },
        {
          alt: 'Travel By Rail',
          src: '/img/landing-pages/pet-airlines/3-Travel-By-Rail.png',
          left: 1400,
          top: 190,
          width: 270
        },
        {
          alt: 'Fly Privately',
          src: '/img/landing-pages/pet-airlines/4-Fly-Privately.png',
          left: 1650,
          top: 110,
          width: 190
        },
      ]
    }

    return (
      <Layout containerClassName={`page page-pet-airlines`} bodyClassName="chat-hidden"
              location={this.props.location} hideFooter={true} hideHeader={true}>
        <SEO { ...this.seo }>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>

        <section ref={ (ref) => this.containerRef = ref } style={{height: `${this.state.vh}px`}}>
          <img ref={ (ref) => this.crateRef = ref } src="/img/landing-pages/pet-airlines/Crate.svg" alt="Dog Pet" className="dog-pet-airline"/>
          <img ref={ (ref) => this.animationRef = ref } src="/img/landing-pages/pet-airlines/DogCrate.gif" alt="Dog Animation" className="dog-crate"/>
          <button ref={ (ref) => this.shareBtnMobileRef = ref } className="crate-share mobile" onClick={(e) => this.showShareDialog(e)}></button>
          <button ref={ (ref) => this.shareBtnRef = ref } className="crate-share desktop" onClick={() => this.displayContent(this.modalShareOptions)}></button>
          <BaseModal ref={(ref) => this.modalShareOptions = ref} title="Share"
                     titleClassName="pet-airlines-modal-title"
                     children={<ContentModalShareOptions />}
                     size='md'
                     scrollable={true}></BaseModal>

          <div className="bg-container first-bg-container">
            { sectionsDesktopImages.firstSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.firstSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

            <div className="title-container">
              <h1>Over 250 Animals Have Died on Airlines Since 2010</h1>
              <div>Here Are The Shocking Secrets Airlines Don’t Want to Tell You About Transporting Pets</div>
            </div>

            <div className="family-walking-text text-center">
              <div className="text-left">For the majority of animal lovers, <br/>pets are more than a source of companionship and love--they’re bonafide members of the family. </div>
              <button onClick={() => this.displayContent(this.modalFamilyWalkingContent)}></button>
              <BaseModal ref={(ref) => this.modalFamilyWalkingContent = ref} title="Intro"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalFamilyWalking />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="tower-container-info text-center">
              <h2>The Grisly Death Toll of Animals on Airlines</h2>
              <div>The United States Department of Transportation (DOT) requires that each airline in the U.S. submit a monthly report on the death, loss, or injury of an animal during air transportation.</div>
              <button onClick={() => this.displayContent(this.modalGrislyDeathToll)}></button>
              <BaseModal ref={(ref) => this.modalGrislyDeathToll = ref} title="The Grisly Death Toll of Animals on Airlines "
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalGrislyDeathToll />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

          </div>

          <div className="bg-container second-bg-container d-none" ref={ (ref) => this.secondContainerRef = ref }>

            { sectionsDesktopImages.secondSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.secondSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

            <div className="shocking-statistics-info d-flex flex-column">
              <button onClick={() => this.displayContent(this.modalShockingStatistics)}></button>
              <BaseModal ref={(ref) => this.modalShockingStatistics = ref} title="Other shocking statistics include:"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalShockingStatistics />}
                         size='lg'
                         scrollable={true}></BaseModal>
              <div className="text-center">Other shocking <br/> statistics include:</div>
              <p>More animals have died on Delta Airlines than on any other airline between 2010 and 2020, accounting for over 30% of  all recorded airline pet deaths in the U.S.</p>
            </div>

          </div>

          <div className="bg-container third-bg-container d-none" ref={ (ref) => this.thirdContainerRef = ref }>

            <div className="man-backpack-modal">
              <button onClick={() => this.displayContent(this.modalManBackpack)}></button>
              <BaseModal ref={(ref) => this.modalManBackpack = ref} title="Why Are So Many Pets Dying on Airlines?"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalManBackpack />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-in-august-modal">
              <button onClick={() => this.displayContent(this.modalBubbleInAugust)}></button>
              <BaseModal ref={(ref) => this.modalBubbleInAugust = ref} title="Why Are So Many Pets Dying on Airlines?"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleInAugust />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            { sectionsDesktopImages.thirdSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.thirdSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

          </div>

          <div className="bg-container fourth-bg-container d-none" ref={ (ref) => this.fourthContainerRef = ref }>

            <div className="bubble-1-separation">
              <button onClick={() => this.displayContent(this.modalBubbleSeparation)}></button>
              <BaseModal ref={(ref) => this.modalBubbleSeparation = ref} title="1. Separation Anxiety and Stress"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleSeparation />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-2-physical">
              <button onClick={() => this.displayContent(this.modalBubblePhysical)}></button>
              <BaseModal ref={(ref) => this.modalBubblePhysical = ref} title="2. Physical Injury"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubblePhysical />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            { sectionsDesktopImages.fourthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.fourthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

          </div>

          <div className="bg-container fifth-bg-container d-none" ref={ (ref) => this.fifthContainerRef = ref }>

            <div className="bubble-3-respiratory">
              <button onClick={() => this.displayContent(this.modalBubbleRespiratory)}></button>
              <BaseModal ref={(ref) => this.modalBubbleRespiratory = ref} title="3. Respiratory Issues"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleRespiratory />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-4-weather-conditions">
              <button onClick={() => this.displayContent(this.modalBubbleWeatherConditions)}></button>
              <BaseModal ref={(ref) => this.modalBubbleWeatherConditions = ref} title="4. Weather Conditions"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleWeatherConditions />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-5-hearing-loss">
              <button onClick={() => this.displayContent(this.modalBubbleHearingLoss)}></button>
              <BaseModal ref={(ref) => this.modalBubbleHearingLoss = ref} title="5. Hearing Loss"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleHearingLoss />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-6-dehydration">
              <button onClick={() => this.displayContent(this.modalBubbleDehydration)}></button>
              <BaseModal ref={(ref) => this.modalBubbleDehydration = ref} title="6. Dehydration"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleDehydration />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="bubble-7-temp-control">
              <button onClick={() => this.displayContent(this.modalBubbleTemperatureContols)}></button>
              <BaseModal ref={(ref) => this.modalBubbleTemperatureContols = ref} title="7. Temperature Contols and Pressurization"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalBubbleTemperatureContols />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            { sectionsDesktopImages.fifthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.fifthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

          </div>

          <div className="bg-container sixth-bg-container d-none" ref={ (ref) => this.sixthContainerRef = ref }>

            <div className="travel-alternatives-for-pets">
              <button onClick={() => this.displayContent(this.modalAlternativesForPets)}></button>
              <BaseModal ref={(ref) => this.modalAlternativesForPets = ref} title="Travel Alternatives for Pets"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalAlternativesForPets />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="hire-a-pet-sitter">
              <button onClick={() => this.displayContent(this.modalHireaPetSitter)}></button>
              <BaseModal ref={(ref) => this.modalHireaPetSitter = ref} title="Hire a Pet Sitter"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalHireaPetSitter />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="take-the-road">
              <button onClick={() => this.displayContent(this.modalTaketheRoad)}></button>
              <BaseModal ref={(ref) => this.modalTaketheRoad = ref} title="Take the Road"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalTaketheRoad />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="travel-by-rail">
              <button onClick={() => this.displayContent(this.modalTravelbyRail)}></button>
              <BaseModal ref={(ref) => this.modalTravelbyRail = ref} title="Travel by Rail or Sea"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalTravelbyRail />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            <div className="fly-privately">
              <button onClick={() => this.displayContent(this.modalFlyPrivately)}></button>
              <BaseModal ref={(ref) => this.modalFlyPrivately = ref} title="Fly Privately"
                         titleClassName="pet-airlines-modal-title"
                         children={<ContentModalFlyPrivately />}
                         size='lg'
                         scrollable={true}></BaseModal>
            </div>

            { sectionsDesktopImages.sixthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-none d-md-block`} />
            })}

            { sectionsMobileImages.sixthSection.map((img, i) => {
              return <img key={i} src={img.src} alt={img.alt} data-lazy-class="img-solid" loading="lazy" style={{top: `${img.top}px`, left: `${img.left}px`, height: `${img.height}px`, width: `${img.width}px`}} className={`${img.class ? img.class : ''} d-md-none`} />
            })}

            <img ref={ (ref) => this.carDesktopRef = ref } src="/img/landing-pages/pet-airlines/Car-new.svg" alt="Car" data-lazy-class="img-solid" loading="lazy" className="car-desktop" />
            <img ref={ (ref) => this.carMobileRef = ref } src="/img/landing-pages/pet-airlines/Car-new.svg" alt="Car" data-lazy-class="img-solid" loading="lazy" className="car-mobile" />
            <img ref={ (ref) => this.dogAnimationCarFixedRef = ref } src="/img/landing-pages/pet-airlines/DogCrate2.gif" alt="Dog Animation" className="dog-inside-car"/>
          </div>

        </section>

      </Layout>
    )
  }
}

export default PetAirlinesPage

export const PetAirlinesPageQuery = graphql`
  query PetAirlinesPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { eq: "landing-pages/pet-airlines/pet-airlines-og-image.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`